import { Card, Checkbox, Col, Form, InputNumber, Radio, Row, Space } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  bonusTypeDict,
  publicDict,
  salaryRewardTypeDict,
  salarySettleTypeDict,
  socialSecurityDict,
  tryUseSalaryDiscountDict,
} from '../dict.ts';
import styles from '../index.less';
import type AddModel from '../model.tsx';
import WelfareTreatment from '../welfareTreatment/index.tsx';
/**
 * 薪资待遇
 */
@inject('store')
@observer
class SalaryAndTreatment extends React.Component<{ store?: AddModel }> {
  render() {
    const { salaryRewardType, tryUseSalaryRewardType, salarySettleType, setValue, hasTryPost, hasTryUse, jobType } =
      this.props.store;
    const { insureListDict, salaryListDict, subsidyListDict, vacationListDict } = this.props.store;

    return (
      <Card
        title="薪资待遇"
        className={styles.card}
      >
        <Space
          size={[20, 16]}
          className={'w-full'}
          direction={'vertical'}
        >
          <Row>
            <Col span={3}>
              <div className={'font-500 text-14 text-[#333333] text-right'}>薪资：</div>
            </Col>
            <Space
              direction={'vertical'}
              className={'w-full'}
            >
              {[1].includes(jobType) ? (
                <Form.Item
                  label={'是否有责'}
                  className={'mb-0'}
                  name={'salaryResponsible'}
                >
                  <Radio.Group options={publicDict} />
                </Form.Item>
              ) : undefined}
              <Form.Item
                className={'mb-0'}
                label={[1].includes(jobType) ? '底薪' : '薪资'}
                required={true}
              >
                <Space>
                  <Form.Item
                    name={'salaryStart'}
                    noStyle={true}
                    rules={[{ required: true, message: '请输入最低薪资' }]}
                  >
                    <InputNumber
                      min={0}
                      maxLength={6}
                      precision={0}
                    />
                  </Form.Item>
                  <div>-</div>
                  <Form.Item
                    name={'salaryEnd'}
                    noStyle={true}
                    rules={[{ required: true, message: '请输入最高薪资' }]}
                  >
                    <InputNumber
                      min={0}
                      maxLength={6}
                      precision={0}
                    />
                  </Form.Item>
                  {[2, 3].includes(jobType) ? <div>元/时</div> : undefined}
                  {[1].includes(jobType) ? <div>*</div> : undefined}
                  {[1].includes(jobType) ? (
                    <Form.Item
                      name={'salaryMonth'}
                      noStyle={true}
                    >
                      <InputNumber
                        min={0}
                        maxLength={3}
                        precision={0}
                      />
                    </Form.Item>
                  ) : undefined}
                  {[1].includes(jobType) ? <div>个月</div> : undefined}
                </Space>
              </Form.Item>
              {[1].includes(jobType) ? (
                <Form.Item
                  className={'mb-0'}
                  label={'是否调薪'}
                  shouldUpdate={true}
                >
                  {({ getFieldValue, setFieldValue }) => {
                    const salaryAdjustment = getFieldValue('salaryAdjustment');
                    return (
                      <Space>
                        <Form.Item
                          name={'salaryAdjustment'}
                          noStyle={true}
                        >
                          <Radio.Group
                            onChange={(e) => {
                              if (e.target.value === 0) {
                                setFieldValue('salaryAdjustmentCycle', undefined);
                              }
                            }}
                            options={publicDict}
                          />
                        </Form.Item>
                        {salaryAdjustment === 1 ? (
                          <Space>
                            <Form.Item
                              name={'salaryAdjustmentCycle'}
                              noStyle={true}
                            >
                              <InputNumber
                                min={0}
                                maxLength={3}
                                precision={0}
                              />
                            </Form.Item>
                            <div>个月</div>
                          </Space>
                        ) : (
                          ''
                        )}
                      </Space>
                    );
                  }}
                </Form.Item>
              ) : undefined}
            </Space>
          </Row>
          <Row>
            <Col span={3}>
              <div className={'font-500 text-14 text-[#333333] text-right'}>提成：</div>
            </Col>
            <Space
              direction={'vertical'}
              className={'w-full'}
            >
              <Form.Item
                label={'提成类型'}
                className={'mb-0'}
                name={'salaryRewardType'}
              >
                <Radio.Group
                  onChange={(e) => {
                    setValue('salaryRewardType', e.target.value);
                  }}
                  options={salaryRewardTypeDict}
                />
              </Form.Item>
              {salaryRewardType ? (
                <Form.Item
                  label={'提成比例'}
                  className={'mb-0'}
                >
                  <Space>
                    <Form.Item
                      name={'salaryRewardStart'}
                      noStyle={true}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        precision={1}
                      />
                    </Form.Item>
                    <div>%</div>
                  </Space>
                </Form.Item>
              ) : undefined}
              {salaryRewardType ? (
                <Form.Item
                  label={'是否有阶梯'}
                  className={'mb-0'}
                  name={'salaryRewardLadder'}
                >
                  <Radio.Group options={publicDict} />
                </Form.Item>
              ) : undefined}
            </Space>
          </Row>
          {[1].includes(jobType) ? (
            <Form.Item
              label={'奖金'}
              className={'mb-0'}
              name={'bonusType'}
            >
              <Radio.Group options={bonusTypeDict} />
            </Form.Item>
          ) : undefined}
          <Form.Item
            label={'工资结算'}
            className={'mb-0'}
            name={'salarySettleType'}
          >
            <Radio.Group
              onChange={(e) => {
                setValue('salarySettleType', e.target.value);
              }}
              options={
                [1].includes(jobType)
                  ? salarySettleTypeDict
                  : [{ label: '当日结算', value: 1 }, ...salarySettleTypeDict]
              }
            />
          </Form.Item>
          {[2, 3].includes(salarySettleType) ? (
            <Form.Item
              label={'结算日期'}
              className={'mb-0'}
            >
              <Space>
                <div>每月</div>
                <Form.Item
                  noStyle={true}
                  name={'salarySettleDate'}
                >
                  <InputNumber
                    min={1}
                    max={31}
                    precision={0}
                  />
                </Form.Item>
                <div>日</div>
              </Space>
            </Form.Item>
          ) : undefined}
          {[1].includes(jobType) ? (
            <Form.Item
              label={'是否缴纳社保'}
              className={'mb-0'}
              name={'hasSocialSecurity'}
            >
              <Radio.Group options={socialSecurityDict} />
            </Form.Item>
          ) : undefined}
          {[1].includes(jobType) ? (
            <Form.Item
              label={'是否缴纳医保'}
              className={'mb-0'}
              name={'hasMedicalSecurity'}
            >
              <Radio.Group options={socialSecurityDict} />
            </Form.Item>
          ) : undefined}
          {[1].includes(jobType) ? (
            <Form.Item
              label={'是否缴纳公积金'}
              className={'mb-0'}
              name={'hasReserveSecurity'}
            >
              <Radio.Group options={socialSecurityDict} />
            </Form.Item>
          ) : undefined}

          {[1].includes(jobType) ? (
            <Form.Item
              className={'mb-0'}
              label={'是否有试岗期'}
              shouldUpdate={true}
            >
              {({ getFieldValue }) => {
                const hasTryPost = getFieldValue('hasTryPost');
                return (
                  <Space>
                    <Form.Item
                      name={'hasTryPost'}
                      noStyle={true}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          setValue('hasTryPost', e.target.value);
                        }}
                        options={publicDict}
                      />
                    </Form.Item>
                    {hasTryPost === 1 ? (
                      <Space>
                        <Form.Item
                          name={'tryPostTime'}
                          noStyle={true}
                        >
                          <InputNumber
                            min={0}
                            maxLength={5}
                            precision={0}
                          />
                        </Form.Item>
                        <div>天</div>
                      </Space>
                    ) : (
                      ''
                    )}
                  </Space>
                );
              }}
            </Form.Item>
          ) : undefined}
          {[1].includes(jobType) && hasTryPost === 1 ? (
            <Form.Item
              className={'mb-0'}
              label={'试岗期是否带薪'}
              shouldUpdate={true}
            >
              {({ getFieldValue }) => {
                const tryPostHasSalary = getFieldValue('tryPostHasSalary');
                return (
                  <Space>
                    <Form.Item
                      name={'tryPostHasSalary'}
                      noStyle={true}
                    >
                      <Radio.Group options={publicDict} />
                    </Form.Item>
                    {tryPostHasSalary === 1 ? (
                      <Space>
                        <Form.Item
                          name={'tryPostSalary'}
                          noStyle={true}
                        >
                          <InputNumber
                            min={0}
                            maxLength={5}
                            precision={0}
                          />
                        </Form.Item>
                        <div>元/天</div>
                      </Space>
                    ) : (
                      ''
                    )}
                  </Space>
                );
              }}
            </Form.Item>
          ) : undefined}
          {[1].includes(jobType) && hasTryPost === 1 ? (
            <Form.Item
              label={'是否安排带教'}
              name={'hasTeaching'}
              className={'mb-0'}
            >
              <Radio.Group options={publicDict} />
            </Form.Item>
          ) : undefined}

          {[1].includes(jobType) ? (
            <Form.Item
              className={'mb-0'}
              label={'是否有试用期'}
              shouldUpdate={true}
            >
              {({ getFieldValue }) => {
                const hasTryUse = getFieldValue('hasTryUse');
                return (
                  <Space>
                    <Form.Item
                      name={'hasTryUse'}
                      noStyle={true}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          setValue('hasTryUse', e.target.value);
                        }}
                        options={publicDict}
                      />
                    </Form.Item>
                    {hasTryUse === 1 ? (
                      <Space>
                        <Form.Item
                          name={'tryUseTime'}
                          noStyle={true}
                        >
                          <InputNumber
                            min={0}
                            maxLength={5}
                            precision={0}
                          />
                        </Form.Item>
                        <div>个月</div>
                      </Space>
                    ) : (
                      ''
                    )}
                  </Space>
                );
              }}
            </Form.Item>
          ) : undefined}
          {[1].includes(jobType) && hasTryUse === 1 ? (
            <Form.Item
              className={'mb-0'}
              label={'薪资打折'}
              shouldUpdate={true}
            >
              {({ getFieldValue }) => {
                const tryUseSalaryDiscount = getFieldValue('tryUseSalaryDiscount');
                return (
                  <Space>
                    <Form.Item
                      name={'tryUseSalaryDiscount'}
                      noStyle={true}
                    >
                      <Radio.Group options={tryUseSalaryDiscountDict} />
                    </Form.Item>
                    {tryUseSalaryDiscount === 1 ? (
                      <Space>
                        <Form.Item
                          name={'tryUseSalaryDiscountProportion'}
                          noStyle={true}
                        >
                          <InputNumber
                            min={0}
                            maxLength={5}
                            precision={0}
                          />
                        </Form.Item>
                        <div>折</div>
                      </Space>
                    ) : (
                      ''
                    )}
                  </Space>
                );
              }}
            </Form.Item>
          ) : undefined}
          {[1].includes(jobType) && hasTryUse === 1 ? (
            <Form.Item
              label={'提成类型'}
              className={'mb-0'}
              name={'tryUseSalaryRewardType'}
            >
              <Radio.Group
                onChange={(e) => {
                  setValue('tryUseSalaryRewardType', e.target.value);
                }}
                options={salaryRewardTypeDict}
              />
            </Form.Item>
          ) : undefined}
          {[1].includes(jobType) && hasTryUse === 1 && tryUseSalaryRewardType ? (
            <Form.Item
              label={'提成比例'}
              className={'mb-0'}
            >
              <Space>
                <Form.Item
                  name={'tryUseSalaryRewardProportion'}
                  noStyle={true}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    precision={1}
                  />
                </Form.Item>
                <div>%</div>
              </Space>
            </Form.Item>
          ) : undefined}
          <Row>
            <Col span={3}>
              <div className={'font-500 text-14 text-[#333333] text-right'}>职位福利：</div>
            </Col>
            <Space
              direction={'vertical'}
              className={'w-full'}
            >
              <Form.Item
                label="保险"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                name={'insureList'}
                className={'mb-0'}
              >
                <Checkbox.Group options={insureListDict} />
              </Form.Item>
              <Form.Item
                label="薪资期权"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                name={'salaryList'}
                className={'mb-0'}
              >
                <Checkbox.Group options={salaryListDict} />
              </Form.Item>
              <Form.Item
                label="度假休假"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                name={'vacation'}
                className={'mb-0'}
              >
                <Checkbox.Group options={vacationListDict} />
              </Form.Item>
              <Form.Item
                label="生活补贴"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                name={'subsidyList'}
                className={'mb-0'}
              >
                <Checkbox.Group options={subsidyListDict} />
              </Form.Item>
            </Space>
          </Row>
        </Space>
      </Card>
    );
  }
}
export default SalaryAndTreatment;
