export const statusDict = {
  2: '待上线',
  1: '已上线',
  3: '待审核',
  4: '审核失败',
};
export const jobTypeDict = {
  1: '全职',
  2: '长期兼职',
  3: '临时兼职',
};
export const requireSexDict = {
  0: '不限',
  1: '男',
  2: '女',
};
export const acceptNewbieDict = {
  0: '否',
  1: '是',
};
export const isKeepOfficeDict = {
  0: '否',
  1: '是',
};
export const hasTryPostDict = {
  0: '否',
  1: '是',
};
export const hasTryUseDict = {
  0: '否',
  1: '是',
};
export const hasSocialSecurityDict = {
  0: '否',
  1: '入职购买',
  2: '转正购买',
};
export const auditStatusDict = {
  0: '审核中',
  1: '审核通过',
  2: '审核失败',
};
export const dataSourceDict = {
  1: 'PBB运营平台',
  2: '小程序',
  3: '导入',
};
